<template>
  <base-card>
    <v-card-title>
      <div class="d-flex justify-space-between align-end" style="width: 100%;">
        <v-btn
          color="primary"
          @click="onAddNewDomain"
        >
          {{ $t('manageDomain.addExternalDomain') }}
        </v-btn>
      </div>
    </v-card-title>

    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="domainList"
        :items-per-page="paginationLimit"
        hide-default-footer
        class="eg-table-bnone eg-table-td-py-md mt-2 px-4"
      >
        <template v-slot:item.isActive="{item}">
          <v-chip
            class="px-4"
            :color="item.isActive ? 'primary' : 'error'"
          >
            {{ item.isActive ? $t('common.active') : $t('common.inActive') }}
          </v-chip>
        </template>

        <template v-slot:item.apiKey="{item}">
          <div style="word-break: break-all;">
            {{ item.visibleApiKey ? item.apiKey : '******' }}
          </div>
        </template>

        <template v-slot:item.action="{item}">
          <div class="d-flex">
            <v-tooltip top>
              <template v-slot:activator="{on, attrs}">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  class="ma-1"
                  fab
                  x-small
                  color="primary"
                  @click="item.visibleApiKey = !item.visibleApiKey"
                >
                  <v-icon>{{ item.visibleApiKey ? 'mdi-eye-off' : 'mdi-eye' }}</v-icon>
                </v-btn>
              </template>
              <span>
                {{ item.visibleApiKey ? $t('manageDomain.hideApiKey') : $t('manageDomain.showApiKey') }}
              </span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{on, attrs}">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  class="ma-1"
                  fab
                  x-small
                  :color="item.isActive ? 'error' : 'primary'"
                  @click="onUpdateExternalDomain(item)"
                >
                  <v-icon>{{ item.isActive ? 'mdi-account-cancel' : 'mdi-account-check' }}</v-icon>
                </v-btn>
              </template>
              <span>
                {{ item.isActive ? $t('manageDomain.disableExternalDomain') : $t('manageDomain.activeExternalDomain') }}
              </span>
            </v-tooltip>
          </div>
        </template>
      </v-data-table>
    </v-card-text>

    <v-card-actions class="justify-center">
      <v-pagination
        v-model="currentPage"
        :length="totalPages"
        color="primary"
        :total-visible="7"
        circle
      />
    </v-card-actions>

    <new-domain-dialog
      v-model="isShowNewDomainDialog"
      @add-new-domain="addNewDomain"
    />
  </base-card>
</template>

<script>
  import { mapActions } from 'vuex'

  import util from '@/utils'
  import { showSuccessDialog, showErrorDialog } from '@/utils/swalDialog'

  import NewDomainDialog from './NewDomainDialog'

  export default {
    metaInfo: {
      title: 'Manage Domains',
    },

    components: {
      NewDomainDialog,
    },

    data () {
      return {
        paginationLimit: 10,
        currentPage: 1,
        totalPages: 0,
        headers: [{
          text: this.$t('partner.companyName'),
          value: 'companyName',
          width: 200,
        }, {
          text: this.$t('manageDomain.domain'),
          value: 'domain',
          width: 200,
        }, {
          text: this.$t('common.status'),
          value: 'isActive',
          width: 150,
        }, {
          text: this.$t('manageDomain.apiKey'),
          value: 'apiKey',
        }, {
          text: this.$t('common.action'),
          value: 'action',
          width: 150,
        }],
        util: util,
        domainList: [],
        isShowNewDomainDialog: false,
      }
    },

    watch: {
      currentPage (val) {
        this.currentPage = val
        this.loadDomainList()
      },
    },

    mounted () {
      this.loadDomainList()
    },

    methods: {
      ...mapActions({
        setLoading: 'setLoading',
        setLoadingText: 'setLoadingText',
        creatExternalDomain: 'creatExternalDomain',
        updateExternalDomain: 'updateExternalDomain',
        fetchExternalDomainList: 'fetchExternalDomainList',
      }),

      async loadDomainList(currentPage = 1) {
        this.currentPage = currentPage
        const pagination = {
          offset: (this.currentPage - 1) * this.paginationLimit,
          limit: this.paginationLimit,
        }

        this.setLoadingText(`${this.$t('common.loading')} ${this.$t(
          'common.data'
        )}...`)
        this.setLoading(true)

        try {
          const response = await this.fetchExternalDomainList(pagination)

          this.totalPages = Math.ceil(response.count / this.paginationLimit)
          this.domainList = response.rows.map((item, index) => {
            return {
              ...item,
              index: (this.currentPage - 1) * this.paginationLimit + index + 1,
              visibleApiKey: false,
            }
          })
        } catch (error) {
          const errorText = util.getErrorResponse(error)
          showErrorDialog(this.$t('common.error'), errorText)
        }
        this.setLoading(false)
      },

      onAddNewDomain() {
        this.isShowNewDomainDialog = true
      },

      async addNewDomain(data) {
        this.isShowNewDomainDialog = false

        this.setLoadingText(this.$t('manageDomain.addingNewExternalDomain'))
        this.setLoading(true)
        try {
          await this.creatExternalDomain(data)

          showSuccessDialog(this.$t('manageDomain.addedExternalDomainSuccessfully'))
          await this.loadDomainList()
        } catch (error) {
          const errorMessage = util.getErrorResponse(error)
          showErrorDialog(this.$t('common.error'), errorMessage)
        }
        this.setLoading(false)
      },

      async onUpdateExternalDomain(item) {
        this.setLoadingText(this.$t('manageDomain.updatingExternalDomain'))
        this.setLoading(true)
        try {
          await this.updateExternalDomain({
            ...item,
            isActive: !item.isActive,
          })

          showSuccessDialog(this.$t('manageDomain.externalDomainUpdated'))
          await this.loadDomainList()
        } catch (error) {
          const errorMessage = util.getErrorResponse(error)
          showErrorDialog(this.$t('common.error'), errorMessage)
        }
        this.setLoading(false)
      }
    },
  }
</script>
