<template>
  <v-dialog
    scrollable
    persistent
    :width="900"
    v-model="dialog">
    <base-card>
      <v-toolbar :color="$vuetify.theme.dark ? 'dark' : 'primary'">
        <v-toolbar-title class="white--text" v-text="$t('manageDomain.addExternalDomain')" />
        <v-spacer />
        <v-btn color="white" icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-form
          ref="form"
          v-model="validForm"
          lazy-validation
        >
          <v-row class="pt-2">
            <v-col cols="12" class="pb-0">
              <v-text-field
                v-model="companyName"
                label="Company Name"
                :rules="companyRules"
              />
            </v-col>

            <v-col cols="12" class="pb-0">
              <v-text-field
                v-model="domain"
                label="Domain"
                :rules="domainRules"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions class="justify-center mb-2">
        <v-btn
          class="primary px-4 mr-4"
          @click="addNewDomain"
        >
          {{ $t('manageDomain.addExternalDomain') }}
        </v-btn>

        <v-btn
          class="error px-4"
          @click.native="dialog = false">
          {{ $t('common.close') }}
        </v-btn>
      </v-card-actions>
    </base-card>
  </v-dialog>
</template>

<script>
  export default {
    name: 'NewDomainDialog',

    props: {
      value: {
        type: Boolean,
        default: false,
      },
    },

    computed: {
      dialog: {
        get() {
          return this.value
        },
        set(v) {
          this.$emit('input', v)
        }
      },
    },

    data() {
      return {
        companyName: '',
        companyRules: [
          (v) => !!v || this.$t('error.fieldRequired', { field: this.$t('partner.companyName') }),
        ],
        domain: '',
        domainRules: [
          (v) => !!v || this.$t('error.fieldRequired', { field: this.$t('manageDomain.domain') }),
          (v) => {
            const pattern = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+|\blocalhost\b)(:\d{1,5})?(\/[\w.-]*)*\/?$/
            return pattern.test(v) || this.$t('error.invalidUrl')
          },
        ],
        validForm: true,
      }
    },

    watch: {
      dialog (value) {
        if (value) {
          this.companyName = ''
          this.domain = ''
        }
      }
    },

    methods: {
      addNewDomain () {
        const isValid = this.$refs.form.validate()

        if (!isValid) {
          return
        }

        this.$emit('add-new-domain', {
          companyName: this.companyName,
          domain: this.domain,
        })
      },
    }
  }
</script>
